<template>
  <div class="dashboard-filters">
    <v-date-filters-desktop
      :date-range="filtersParams.dateRangeType"
      :custom-date-range="[filtersParams.dateFrom, filtersParams.dateTo]"
      @change="changeDateRange"
    />

    <div class="dashboard-filters__row">
      <button
        v-for="category in categoryOptions"
        :key="category.id"
        class="dashboard-filters__filter-button"
        :class="{ 'dashboard-filters__filter-button--active': filtersParams.categoryId === category.id }"
        @click="changeCategory(category.id)"
      >
        {{ category.label }}
      </button>
    </div>
  </div>
</template>

<script>
import VDateFiltersDesktop from '@/components/common/VDateFiltersDesktop.vue'
import { advertsService } from '@/services/http'

export default {
  name: 'DashboardGeneralFilters',
  components: { VDateFiltersDesktop },
  props: {
    defaultFilters: { type: Object, default: () => {} }
  },
  data() {
    return {
      categoryOptions: [],
      filtersParams: {
        dateFrom: undefined,
        dateTo: undefined,
        dateRangeType: undefined,
        isSimilarRange: undefined,
        categoryId: undefined
      }
    }
  },
  watch: {
    defaultFilters: {
      immediate: true,
      deep: true,
      handler(newFilters) {
        this.filtersParams = { ...newFilters }
      }
    }
  },
  created() {
    this.selectCategory()
  },
  methods: {
    selectCategory() {
      return advertsService.selectCategory().then(categories => {
        categories.unshift({
          name: 'Все объекты',
          id: null
        })
        this.categoryOptions = categories.map(category => {
          return {
            label: category.name,
            id: category.id
          }
        })
      })
    },
    changeDateRange({ dateRange, customDateRange }) {
      const [dateFrom, dateTo] = customDateRange || []
      this.emitFilters({ dateRangeType: dateRange, dateFrom, dateTo })
    },
    changeFilters(params) {
      this.emitFilters(params)
    },
    changeCategory(categoryId) {
      this.emitFilters({ categoryId })
    },
    emitFilters(params) {
      this.$emit('change', { ...this.filtersParams, ...params })
    }
  }
}
</script>
