<template>
  <match-media v-slot="{ mobile }">
    <div class="dashboard-calls">
      <v-button-go-back
        v-if="mobile && localSectionActiveMenuItem"
        class="dashboard-calls__button-go-back"
        @click="goBack"
      />
      <h3 v-if="sectionActiveMenuItem" class="dashboard-calls__title">Анализ лидов</h3>

      <div v-if="!localSectionActiveMenuItem" class="dashboard-calls__line-chart-section">
        <!-- Calls chart -->
        <v-chart-with-filters
          class="dashboard-calls__chart"
          type="line"
          title="Лиды"
          :count="calls.total"
          :datasets="calls.datasets"
          :labels="calls.labels"
          :titles="calls.titles"
          :filters="calls.filters"
          with-similar-range
          with-date-filter
          with-agent-select
          @change-filters="changeGraphFilters($event, 'calls')"
        />

        <!-- Calls categories chart -->
        <v-chart-with-filters
          class="dashboard-calls__chart"
          type="line"
          title="Лиды. Категории"
          :datasets="callsCategories.datasets"
          :labels="callsCategories.labels"
          :titles="callsCategories.titles"
          :filters="callsCategories.filters"
          :chart-options="{ legend: true }"
          with-similar-range
          with-date-filter
          with-agent-select
          @change-filters="changeGraphFilters($event, 'callsCategories')"
        />

        <!-- Agents chart -->
        <v-chart-with-filters
          class="dashboard-calls__chart"
          type="bar"
          title="Лиды. Агенты"
          :datasets="callsAgents.datasets"
          :labels="callsAgents.labels"
          :filters="callsAgents.filters"
          :chart-options="{ legend: true }"
          with-category-select
          @change-filters="changeGraphFilters($event, 'callsAgents')"
        />

        <!-- calls sources chart -->
        <v-chart-with-filters
          class="dashboard-calls__chart"
          type="bar"
          title="Лиды. Источники"
          :datasets="callsSources.datasets"
          :labels="callsSources.labels"
          :filters="callsSources.filters"
          :chart-options="{ legend: true }"
          with-category-select
          with-date-filter
          units="%"
          @change-filters="changeGraphFilters($event, 'callsSources')"
        />

        <!-- calls conversion chart -->
        <v-chart-with-filters
          class="dashboard-calls__chart"
          type="bar"
          title="Конверсия лидов в показы среди агентов"
          :datasets="callsConversion.datasets"
          :labels="callsConversion.labels"
          :filters="callsConversion.filters"
          :chart-options="{ legend: true }"
          with-category-select
          with-date-filter
          units="%"
          @change-filters="changeGraphFilters($event, 'callsConversion')"
        />

        <!-- advert average prices chart -->
        <v-chart-with-filters
          class="dashboard-calls__chart"
          type="line"
          title="Лиды. Средняя стоимость объекта"
          :datasets="callsAdvertAveragePrices.datasets"
          :labels="callsAdvertAveragePrices.labels"
          :filters="callsAdvertAveragePrices.filters"
          :titles="callsAdvertAveragePrices.titles"
          :chart-options="{ legend: true, yAxesRoundMoney: true }"
          with-agent-select
          with-similar-range
          with-date-filter
          money-round
          @change-filters="changeGraphFilters($event, 'callsAdvertAveragePrices')"
        />
      </div>

      <!-- agent-metrics section -->
      <div
        v-if="!mobile || localSectionActiveMenuItem === $options.TOP_AGENTS"
        class="dashboard-calls__agent-metrics-section"
      >
        <agent-metrics-list
          title="Лучшие агенты."
          is-month-select-visible
          :list="agentBestList"
          :date="dateCallsAgentBestList"
          class="dashboard-calls__agent-metrics-list dashboard-calls__agent-metrics-list--left"
          @change="refetchBestAgentList"
        ></agent-metrics-list>
        <agent-metrics-list
          title="Неэффективные агенты."
          :list="agentIneffectiveList"
          :date="dateCallsAgentIneffectiveList"
          is-month-select-visible
          class="dashboard-calls__agent-metrics-list dashboard-calls__agent-metrics-list--right"
          @change="refetchIneffectiveAgentList"
        ></agent-metrics-list>
      </div>

      <div v-if="mobile && !localSectionActiveMenuItem" class="dashboard-calls__mobile-menu">
        <v-menu v-model="localSectionActiveMenuItem">
          <v-menu-item :name="$options.TOP_AGENTS">
            <span class="dashboard-calls__menu-item">
              ТОП агентов
            </span>
          </v-menu-item>
        </v-menu>
      </div>
    </div>
  </match-media>
</template>

<script>
import { MatchMedia } from 'vue-component-media-queries'
import VChartWithFilters from '@/components/common/VChartWithFilters.vue'
import AgentMetricsList from '@/components/Agent/Metrics/List.vue'
import VMenu from '@/components/common/VMenu.vue'
import VMenuItem from '@/components/common/VMenuItem.vue'
import VButtonGoBack from '@/components/common/VButtonGoBack.vue'
import { advertsService, statisticService } from '@/services/http'
import { loadingService } from '@/services/loading'
import { getColorByIndex, getLightColorByIndex } from '@/components/common/VChart/utils'
import { abbreviatedDate, createChartDataset } from '@/utils/common'
import { CUSTOM_DATE_TYPE, SIX_MONTH_TYPE, THIS_MONTH_TYPE } from '@/constants/dateRangeTypes'
import { TOP_AGENTS } from '@/constants/dashboard'

const FETCH_METHOD_BY_GRAPH_NAME = {
  calls: 'fetchCallsGraph',
  callsCategories: 'fetchCallCategoriesGraph',
  callsAgents: 'fetchCallsAgentsGraph',
  callsConversion: 'fetchCallsConversionGraph',
  callsSources: 'fetchCallsSourcesGraph',
  callsAdvertAveragePrices: 'fetchCallsAveragePricesGraph'
}

export default {
  TOP_AGENTS,
  name: 'DashboardCalls',
  components: { MatchMedia, VChartWithFilters, VMenu, VMenuItem, AgentMetricsList, VButtonGoBack },
  props: {
    sectionActiveMenuItem: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      calls: {
        filters: {
          dateFrom: null,
          dateTo: null,
          dateRangeType: SIX_MONTH_TYPE,
          isSimilarRange: false,
          agentId: null
        },
        total: 0,
        datasets: [],
        labels: [],
        titles: {
          current: [],
          similar: []
        }
      },
      callsCategories: {
        filters: {
          dateRangeType: SIX_MONTH_TYPE,
          dateFrom: null,
          dateTo: null,
          isSimilarRange: false,
          agentId: null
        },
        datasets: [],
        labels: [],
        titles: {
          current: [],
          similar: []
        }
      },
      callsAgents: {
        filters: {
          categoryId: null
        },
        datasets: [],
        labels: []
      },
      callsConversion: {
        filters: {
          dateRangeType: SIX_MONTH_TYPE,
          dateFrom: null,
          dateTo: null,
          categoryId: null
        },
        datasets: [],
        labels: []
      },
      callsSources: {
        filters: {
          dateRangeType: SIX_MONTH_TYPE,
          dateFrom: null,
          dateTo: null,
          categoryId: null
        },
        datasets: [],
        labels: []
      },
      callsAdvertAveragePrices: {
        filters: {
          dateRangeType: SIX_MONTH_TYPE,
          dateFrom: null,
          dateTo: null,
          isSimilarRange: false,
          agentId: null
        },
        datasets: [],
        labels: [],
        titles: {
          current: [],
          similar: []
        }
      },
      categories: [],
      dateCallsAgentBestList: new Date(),
      dateCallsAgentIneffectiveList: new Date(),
      agentBestList: [],
      agentIneffectiveList: []
    }
  },
  computed: {
    localSectionActiveMenuItem: {
      get() {
        return this.sectionActiveMenuItem
      },
      set(newValue) {
        this.$emit('update:sectionActiveMenuItem', newValue)
      }
    }
  },
  created() {
    loadingService.setViewLoading(true)

    this.fetchCategories()
      .then(() => {
        const callsRequest = this.fetchCallsGraph()
        const callsCategoriesRequest = this.fetchCallCategoriesGraph()
        const callsAgentsRequest = this.fetchCallsAgentsGraph()
        const callsConversionRequest = this.fetchCallsConversionGraph()
        const callsSourcesRequest = this.fetchCallsSourcesGraph()
        const callsAveragePricesRequest = this.fetchCallsAveragePricesGraph()
        const agentBestCallsList = this.fetchBestAgentList()
        const agentIneffectiveCallsList = this.fetchIneffectiveAgentList()

        return Promise.all([
          callsRequest,
          callsCategoriesRequest,
          callsAgentsRequest,
          callsConversionRequest,
          callsSourcesRequest,
          callsAveragePricesRequest,
          agentBestCallsList,
          agentIneffectiveCallsList
        ])
      })

      .finally(() => {
        loadingService.setViewLoading(false)
      })
  },
  inject: ['graphsMainColor'],
  methods: {
    fetchCategories() {
      return advertsService.selectCategory().then(data => {
        this.categories = data
      })
    },

    // fetch Graph
    fetchCallsGraph() {
      return statisticService.getCallsGraph(this.calls.filters).then(data => {
        const datasets = []

        const defaultDataset = createChartDataset({
          data: data.graph,
          color: this.graphsMainColor,
          label: 'Лиды',
          filled: true
        })
        datasets.push(defaultDataset)

        if (data.similarRangeGraph) {
          const similarDataset = createChartDataset({
            data: data.similarRangeGraph,
            color: 'green',
            label: 'Лиды в похожем периоде'
          })
          datasets.push(similarDataset)
          this.calls.titles.similar = data.similarRangeGraph.map(i => i.date)
        } else {
          this.calls.titles.similar = []
        }

        this.calls.datasets = datasets
        this.calls.labels = data.graph.map(i => i.date)
        this.calls.titles.current = data.graph.map(i => i.date)
        this.calls.total = data.sumOfValues

        if (![THIS_MONTH_TYPE, CUSTOM_DATE_TYPE].includes(this.calls.filters.dateRangeType)) {
          this.formatToAbbrDates('calls', true)
        }
      })
    },
    fetchCallCategoriesGraph() {
      return statisticService.getCallsCategoriesGraph(this.callsCategories.filters).then(data => {
        const datasets = []

        data.graphs.forEach(({ graph, category }, index) => {
          const label = this.categories.find(cat => cat.id === category).name
          const dataset = createChartDataset({ data: graph, color: getColorByIndex(index), label })
          datasets.push(dataset)
        })

        if (data.similarRangeGraphs) {
          data.similarRangeGraphs.forEach(({ graph, category }) => {
            const label = this.categories.find(cat => cat.id === category).name
            const index = datasets.findIndex(d => d.label === label)

            const dataset = createChartDataset({
              data: graph,
              color: getLightColorByIndex(index),
              label: `%${label}`
            })
            datasets.push(dataset)
            this.callsCategories.titles.similar = data.similarRangeGraphs.length
              ? data.similarRangeGraphs[0].graph.map(i => i.date)
              : []
          })
        } else {
          this.callsCategories.titles.similar = []
        }

        this.callsCategories.datasets = datasets
        this.callsCategories.titles.current = data.graphs.length ? data.graphs[0].graph.map(i => i.date) : []
        this.callsCategories.labels = data.graphs.length ? data.graphs[0].graph.map(i => i.date) : []

        if (![THIS_MONTH_TYPE, CUSTOM_DATE_TYPE].includes(this.callsCategories.filters.dateRangeType)) {
          this.formatToAbbrDates('callsCategories', true)
        }
      })
    },
    fetchCallsAgentsGraph() {
      return statisticService
        .getCallsAgentsGraph({ ...this.callsAgents.filters, dateRangeType: SIX_MONTH_TYPE })
        .then(data => {
          const datasets = []

          data.graphs.forEach(({ graph, agent }, index) => {
            const label = agent.name
            const dataset = createChartDataset({ data: graph, color: getColorByIndex(index), label })
            datasets.push(dataset)
          })

          this.callsAgents.datasets = datasets
          const labels = data.graphs.length ? data.graphs[0].graph.map(i => i.date) : []
          this.callsAgents.labels = abbreviatedDate(labels)
        })
    },
    fetchCallsConversionGraph() {
      return statisticService.getCallsConversionGraph(this.callsConversion.filters).then(data => {
        const datasets = []
        data.forEach(({ graph, agent }, index) => {
          const label = agent.name
          const dataset = createChartDataset({ data: graph, color: getColorByIndex(index), label })
          datasets.push(dataset)
        })
        this.callsConversion.datasets = datasets
        const labels = data.length ? data[0].graph.map(i => i.date) : []
        this.callsConversion.labels = abbreviatedDate(labels)
      })
    },
    fetchCallsSourcesGraph() {
      return statisticService.getCallsSourcesGraph(this.callsSources.filters).then(data => {
        const datasets = []

        data.forEach(({ graph, callSourceName }, index) => {
          const label = callSourceName
          const dataset = createChartDataset({ data: graph, color: getColorByIndex(index), label })
          datasets.push(dataset)
        })

        this.callsSources.datasets = datasets
        const labels = data.length ? data[0].graph.map(i => i.date) : []
        this.callsSources.labels = abbreviatedDate(labels)
      })
    },
    setDatasets(chart, datasets, isSimilar = false) {
      chart.forEach(({ graph, advertCategory }, index) => {
        const label = advertCategory.name
        const localIndex = isSimilar ? datasets.findIndex(d => d.label === label) : index

        const dataset = createChartDataset({
          data: graph,
          color: isSimilar ? getLightColorByIndex(localIndex) : getColorByIndex(localIndex),
          label: isSimilar ? `%${label}` : label
        })
        datasets.push(dataset)
      })
    },
    fetchCallsAveragePricesGraph() {
      return statisticService.getCallsAdvertAveragePricesGraph(this.callsAdvertAveragePrices.filters).then(data => {
        const datasets = []
        this.setDatasets(data.mainChart, datasets)

        const dateLabels = data.mainChart?.length ? data.mainChart[0]?.graph.map(i => i.date) : []

        this.callsAdvertAveragePrices.labels = dateLabels
        this.callsAdvertAveragePrices.titles.current = dateLabels
        this.callsAdvertAveragePrices.titles.similar = []

        if (data.similarChart?.length) {
          this.setDatasets(data.similarChart, datasets, true)
          this.callsAdvertAveragePrices.titles.similar = data.similarChart[0].graph.map(i => i.date)
        }

        this.callsAdvertAveragePrices.datasets = datasets
        if (![THIS_MONTH_TYPE, CUSTOM_DATE_TYPE].includes(this.callsAdvertAveragePrices.filters.dateRangeType)) {
          this.formatToAbbrDates('callsAdvertAveragePrices', true)
        }
      })
    },

    formatToAbbrDates(field, withTitles = false) {
      if (withTitles) {
        this[field].titles.current = abbreviatedDate(this[field].titles.current)
        this[field].titles.similar = abbreviatedDate(this[field].titles.similar)
      }
      this[field].labels = abbreviatedDate(this[field].labels)
    },

    fetchBestAgentList(params) {
      return statisticService.getBestAgentsCallsList(params).then(list => {
        this.agentBestList = list
      })
    },

    fetchIneffectiveAgentList(params) {
      return statisticService.getIneffectiveAgentsCallsList(params).then(list => {
        this.agentIneffectiveList = list
      })
    },

    refetchBestAgentList(newDate) {
      this.dateCallsAgentBestList = newDate
      const month = newDate.getMonth() + 1
      const year = newDate.getFullYear()
      loadingService.setViewLoading(true)
      this.fetchBestAgentList({ month, year }).finally(() => {
        loadingService.setViewLoading(false)
      })
    },

    refetchIneffectiveAgentList(newDate) {
      this.dateCallsAgentIneffectiveList = newDate
      const month = newDate.getMonth() + 1
      const year = newDate.getFullYear()

      loadingService.setViewLoading(true)
      this.fetchIneffectiveAgentList({ month, year }).finally(() => {
        loadingService.setViewLoading(false)
      })
    },

    refetchGraphList(graph) {
      loadingService.setViewLoading(true)
      this[FETCH_METHOD_BY_GRAPH_NAME[graph]]().finally(() => {
        loadingService.setViewLoading(false)
      })
    },
    changeGraphFilters(filters, graph) {
      this[graph].filters = filters
      this.refetchGraphList(graph)
    },
    goBack() {
      this.localSectionActiveMenuItem = undefined
    }
  }
}
</script>
